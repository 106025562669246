import React from "react"
import { Form, Container } from "../../components"

import Seo from "../../components/seo"
const ContactPage = () => {
  return (
    <Container>
      <Seo title="お問い合わせ" />
      <Form />
    </Container>
  )
}

export default ContactPage
